@use '@angular/material' as mat;
@import '_palette';

@include mat.core();

$my-primary: mat.define-palette(mat.$blue-palette, 500);
$my-accent: mat.define-palette($md-ntrdeaccent, 500);

$typography: mat.define-typography-config($font-family: '"Space Grotesk", sans-serif;');

$theme: mat.define-light-theme((color: (primary: $my-primary,
                accent: $my-accent,
                white: #fff,
                error: mat.define-palette(mat.$red-palette, 700)),
            typography: $typography,
            density: -1));


.dense-input {
    @include mat.all-component-densities(-5);

    .dense-0 {
        @include mat.all-component-densities(0);
    }
}

@include mat.all-component-themes($theme);

:root {
    --color-primary: #{mat.get-color-from-palette($my-primary)};
    --color-accent: #{mat.get-color-from-palette($my-accent)};
    --color-accent-100: #0072E5;
    --color-accent-200: #004988;
}

.mat-mdc-unelevated-button>.mat-icon.centered {
    margin: 0;
}