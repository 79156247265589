@font-face {
    font-family: "Space Grotesk";
    font-style: light;
    font-weight: 300;
    src:
        url(/assets/font/SpaceGrotesk-Light.ttf) format("truetype")
}

@font-face {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    src:
        url(/assets/font/SpaceGrotesk-Regular.ttf) format("truetype")
}

@font-face {
    font-family: "Space Grotesk";
    font-style: medium;
    font-weight: 500;
    src:
        url(/assets/font/SpaceGrotesk-Medium.ttf) format("truetype")
}

@font-face {
    font-family: "Space Grotesk";
    font-weight: 600;
    src:
        url(/assets/font/SpaceGrotesk-SemiBold.ttf) format("truetype")
}

@font-face {
    font-family: "Space Grotesk";
    font-style: bold;
    font-weight: 700;
    src:
        url(/assets/font/SpaceGrotesk-Bold.ttf) format("truetype")
}