@import 'themes.scss';

:root {
    --mat-border-radius: 8px;
    --mat-paginator-container-background-color: none;
}

.mat-button,
.mat-input-element {
    --mdc-outlined-text-field-container-shape: var(--mat-border-radius) !important;
}

.mat-mdc-table {
    padding: 8px; //add padding to see the border-radius
    border-radius: var(--mat-border-radius);

    .mat-mdc-header-cell {
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.06);

    }
}